import { Input } from 'antd'
import { useState, useMemo } from 'react'
import {
  IOptionLabel,
  ITimelineData,
  TaskCtrlAppCard,
} from '../../interface/org'
import Select from '../../components/input/Select'
import {
  useDiscipline,
  useDisciplineUsers,
  useKeypoints,
  useMainProcesses,
  useUsers,
} from 'src/query/taskCtrl'
import { useUnsyncedKeypoints, useUpdateAppCard } from 'src/query/tableKeeper'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { isSynced } from '../../models/TaskCtrlItem'
import { formatDate } from '../../util/time'
import dayjs from 'dayjs'
import {
  isInPlanningPeriod,
  syncedWithTaskCtrlVersion,
} from '../../util/updateCard'
import KeypointSelector from 'src/components/keypoint/KeypointSelector'

const { TextArea } = Input

const DeliveryCardDetailPage = ({
  card,
  onUpdate,
  timeline,
}: {
  card: TaskCtrlAppCard
  onUpdate: () => void
  timeline: ITimelineData
}) => {
  const synced =
    isSynced(card) && !isInPlanningPeriod(dayjs(card.deadline), timeline)
  const tempKeypoints = useUnsyncedKeypoints()

  const [selectedKeypoint, setSelectedKeypoint] = useState(card.keypoint)
  const [selectedUser, setSelectedUser] = useState(card.user)
  const [selectedResponsible, setSelectedResponsible] = useState(
    card.responsible,
  )
  const [selectedMainProcess, setSelectedMainProcess] = useState(
    card.mainProcess,
  )

  const [title, setTitle] = useState(card.title)
  const [description, setDescription] = useState(card.description)

  const { data: disciplineUsers } = useDisciplineUsers({
    discipline: card.discipline?.id,
  })

  const { data: keypoints } = useKeypoints()
  const { data: users } = useUsers()
  const { data: mainProcesses } = useMainProcesses()

  const { data: selectedDiscipline } = useDiscipline(card.discipline?.id)
  const updateDelivery = useUpdateAppCard(card.id)

  const options = useMemo(() => {
    return [
      {
        label: 'Un Synced Keypoints',
        options: tempKeypoints.map((k) => ({
          value: k.id,
          label: k.title,
          deadline: k.deadline,
        })),
      },
      {
        label: 'From TaskCtrl',
        options: (keypoints ?? []).map((k) => ({
          value: k.id,
          label: k.name,
          deadline: k.endTime,
        })),
      },
    ]
  }, [tempKeypoints, keypoints])

  const disableButton = useMemo(() => {
    return selectedKeypoint === undefined || title === undefined
  }, [selectedKeypoint, title])

  const updateTitle = (_title: string) => {
    setTitle(_title)
  }

  const updateDescription = (desc: string) => {
    setDescription(desc)
  }

  const updateResponsible = (id: number, op: IOptionLabel) => {
    setSelectedResponsible({ id, label: op.label })
  }

  const setKeypoint = (id: number, op: IOptionLabel) => {
    setSelectedKeypoint({ id, label: op.label, deadline: op.deadline })
  }

  const setReporter = (id: number, op: IOptionLabel) => {
    setSelectedUser({ id, label: op.label })
  }

  const setProcess = (id: number, op: IOptionLabel) => {
    setSelectedMainProcess({ id, label: op.label })
  }

  const onSave = async () => {
    const updatedDelivery = {
      ...card,
      title,
      description,
      responsible: selectedResponsible,
      keypoint: selectedKeypoint,
      user: selectedUser,
      mainProcess: selectedMainProcess,
    }

    await updateDelivery.mutateAsync({
      ...updatedDelivery,
      synced: syncedWithTaskCtrlVersion(
        updatedDelivery,
        updatedDelivery.taskCtrlVersion,
      ),
    })

    onUpdate()
    await miro.board.ui.closeModal()
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="">Title*</div>
        <Input
          placeholder="Add Title"
          value={title}
          onChange={(e) => updateTitle(e.target.value)}
          disabled={synced}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="">Description</div>
        <TextArea
          rows={4}
          placeholder="Add Description"
          value={description}
          onChange={(e) => updateDescription(e.target.value)}
          disabled={synced}
        />
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col gap-2 w-1/2">
          <div className="">Deadline*</div>
          <Input
            placeholder="Deadline"
            value={formatDate(card.deadline)}
            disabled
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <div className="">Discipline*</div>
          <Input
            placeholder="Discipline"
            value={selectedDiscipline?.name}
            disabled
          />
        </div>
      </div>
      <div className="flex gap-2">
        <div className="w-1/2">
          <Select
            onChange={updateResponsible}
            name={['firstName', 'lastName']}
            value={selectedResponsible}
            label={'Responsible*'}
            items={disciplineUsers ?? []}
            error={!selectedResponsible || selectedResponsible.id < 0}
            disabled={!selectedDiscipline || synced}
            placeholder={
              !selectedDiscipline
                ? 'Select a discipline to show available users'
                : 'Select responsible'
            }
            id={'id'}
          />
        </div>
        <div className="w-1/2">
          <Select
            onChange={setReporter}
            name={['firstName', 'lastName']}
            value={selectedUser}
            label={'Reporter*'}
            items={users ?? []}
            error={!selectedUser || selectedUser.id < 0}
            id={'id'}
            disabled={synced}
          />
        </div>
      </div>
      <div className="flex gap-2">
        <div className="w-1/2">
          <Select
            onChange={setKeypoint}
            name={['title']}
            value={selectedKeypoint}
            label={'Keypoint*'}
            items={[]}
            groupOption={options}
            error={!selectedKeypoint || selectedKeypoint.id === -1}
            id={'id'}
            disabled={synced}
            optionRenderer={(op) => (
              <KeypointSelector
                keypoint={op.data}
                deadline={card.deadline ?? ''}
              />
            )}
          />
        </div>
        <div className="w-1/2">
          <Select
            onChange={setProcess}
            name={['name']}
            value={selectedMainProcess}
            label={'Main process'}
            items={mainProcesses ?? []}
            id={'id'}
            disabled={synced}
          />
        </div>
      </div>

      <div className="flex mt-2 gap-3 items-center justify-center">
        {synced ? (
          <div>
            <div className={'flex items-center justify-center gap-2'}>
              <CheckCircleTwoTone
                style={{ fontSize: 'x-large' }}
                twoToneColor={'#43bc4f'}
              />
              <p> Already synced </p>
            </div>
            <p> Not possible to update items in the past period</p>
          </div>
        ) : (
          <button
            onClick={onSave}
            disabled={disableButton}
            className={'button  sticky bottom-0 button-primary '}
          >
            Update delivery
          </button>
        )}
      </div>
    </div>
  )
}

export default DeliveryCardDetailPage

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react'
import { Select as AntSelect } from 'antd'
import { IOptionLabel } from '../../interface/org'

interface SelectProps {
  onChange: (v: any, option: IOptionLabel) => void
  value: any
  name: string[]
  label: string
  items: any[]
  id: string
  mode?: 'multiple' | 'tags'
  disabled?: boolean
  error?: boolean
  groupOption?: any[]
  placeholder?: string
  allowClear?: boolean
  optionRenderer?: (option: any) => JSX.Element
}

const Select: FC<SelectProps> = ({
  label,
  items,
  name,
  id,
  value,
  onChange,
  mode,
  disabled,
  error,
  groupOption,
  placeholder,
  allowClear,
  optionRenderer,
}) => {
  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  return (
    <>
      <label htmlFor={label}>{label}</label>
      <AntSelect
        allowClear={allowClear}
        placeholder={placeholder ?? 'Choose a value'}
        showSearch
        value={value == -1 ? undefined : value}
        mode={mode}
        className="my-2 w-full"
        id={label}
        status={error ? 'error' : undefined}
        filterOption={filterOption}
        disabled={disabled}
        optionRender={optionRenderer}
        onChange={(v, o) => onChange(v, Array.isArray(o) ? o[0] : o)}
        options={
          groupOption ??
          items.map((item) => ({
            value: item[id],
            label: name.map((n) => item[n]).join(' '),
          }))
        }
      />
    </>
  )
}

export default Select

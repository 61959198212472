import dayjs, { Dayjs } from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import en_GB from 'dayjs/locale/en-gb'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(weekOfYear)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(updateLocale)

export const setTimezoneAndLocale = () => {
  dayjs.tz.guess()
  dayjs.locale(en_GB)
}

export const formatDate = (iso?: string): string => {
  if (!iso) return ''
  const date = dayjs(iso)
  return date.format('DD.MM.YYYY')
}

export const weekFormat = (iso: string): string => {
  const date = dayjs(iso)
  return date.day(5).format('DD.MM.YYYY')
}

export const beforeTime = (isoA: string, isoB: string): boolean => {
  try {
    const a = dayjs(isoA)
    const b = dayjs(isoB)
    return a.isBefore(b)
  } catch (error) {
    return false
  }
}

export class YearWeek {
  readonly year: number
  readonly week: number

  constructor(day: Dayjs) {
    const year = day.year()
    const week = day.week()
    if (day.month() === 11 && week === 1) {
      this.year = year + 1
    } else {
      this.year = year
    }
    this.week = week
  }

  getIsoFriday(): string {
    const friday = dayjs().week(this.week).year(this.year).day(5)
    return friday.toISOString()
  }

  before(other: YearWeek): boolean {
    return this.year !== other.year
      ? this.year < other.year
      : this.week < other.week
  }

  equals(other: YearWeek): boolean {
    return this.year === other.year && this.week === other.week
  }

  toString(): string {
    return `Uke ${this.week}`
  }

  diffInWeek(other: YearWeek): number {
    return dayjs()
      .week(this.week)
      .year(this.year)
      .diff(dayjs().week(other.week).year(other.year), 'week')
  }

  isBeforeOrEqual(other: YearWeek): boolean {
    return this.before(other) || this.equals(other)
  }

  toDayJs() {
    return dayjs().week(this.week).year(this.year)
  }

  static clone(org: YearWeek): YearWeek {
    return new YearWeek(dayjs().week(org.week).year(org.year))
  }
}
